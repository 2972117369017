import api from './base'

export default {
  getAll () {
    return api().get('projects')
  },
  getById ({ id }) {
    return api().get(`projects/${id}`)
  },
  create ({ item }) {
    return api().post('projects', item)
  },
  update ({ id, item }) {
    return api().put(`projects/${id}`, item)
  },
  delete ({ id }) {
    return api().delete(`projects/${id}`)
  },
}
